import { QueryKey } from '@tanstack/react-query'
import { UploadedDocumentInfo } from '@urbanx/agx-ui-components'
import CampaignsService from '../../campaignsService'

export type DocumentGroup = {
  title: string,
  documents: UploadedDocumentInfo[]
}

const GetDocumentsForCampaign = async (
  { queryKey }: { queryKey: QueryKey },
  getAuthToken: () => Promise<string | void>
): Promise<DocumentGroup[] | undefined> => {
  const token = await getAuthToken()
  if (!token) return

  const service = new CampaignsService(token)

  if (!service) return

  const campaignId = queryKey[1] as string

  const result = await service.campaignsService.get(
    'StaffGetDocumentsForCampaign',
    { campaignId }
  )

  return result.data
}

export default GetDocumentsForCampaign